// Alerts list reducer

// Importing strings to avoid typos

import {FETCH_ALERT_LIST_BEGIN} from '../actions/actionTypes';
import {FETCH_ALERT_LIST_SUCCESS} from '../actions/actionTypes';
import {FETCH_ALERT_LIST_FAILURE} from '../actions/actionTypes';
import {INVALIDATE_ALERT_LIST} from '../actions/actionTypes';

const initialState = {
	loading: false, 
	error: 'null', 
	failed: false,
	alerts_list: [],
	prev_n_alerts: 0,
	t_fetched: new Date(0),
}

export default function alertsList(state = initialState, action) {
	// this will need to return an alerts list 
	switch(action.type) {
		case FETCH_ALERT_LIST_BEGIN:
			// We want to mark this as loading and reset errors as we're starting again. 
			return Object.assign({}, state, {
				loading: true,
				error: 'null',
				failed: false
			});
		case FETCH_ALERT_LIST_SUCCESS:
	 		// This means by some miracle that we're been able to get data from
	 		// the API and have a success
	 		return Object.assign({}, state, {
				loading: false,
				error: null,
				failed: false,
				prev_n_alerts: state.alerts_list.length,
				alerts_list: state.alerts_list.concat(action.payload),
				t_fetched: action.time_fetched, 
			});
			
	 	case FETCH_ALERT_LIST_FAILURE:
 			// This means that we haven't been able to get data from the API and should
	 		// return a error

	 		return Object.assign({}, state, {
	 			loading: false,
				error: action.payload,
				failed: true,
				t_fetched: action.time_fetched,
			 });
			
		case INVALIDATE_ALERT_LIST:
			return Object.assign({}, state, {
				alerts_list: [],
				failed: false, 
				loading: false, 
				prev_n_alerts: 0,
				t_fetched: action.time_fetched,
			});
	 	default:
	 		// we ALWAYS have to have a default case so we shall just return the initial state.
	 		return state
	}
}