import React, {ChangeEvent, ChangeEventHandler, FC, ToggleEventHandler, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button} from '@material-ui/core';
import { AlertFilterMap } from '../../Alerts/alertFilterMap';
import { FAR_DEFAULT_SIGNIFICANCE_THRESHOLD } from '../../consts';
import { RootState } from '../../redux/store';
import {
    toggleFarThreshold,
    toggleShowBbh,
    toggleShowBns,
    toggleShowBurst,
    toggleShowMassgap,
    toggleShowNoise,
    toggleShowNsbh,
    toggleRetracted
} from "../../redux/actions/filterAlertList";
import { invalidateAlertsList, retrieveAlertsPage } from '../../redux/actions/fetchAlertList';
import { modalStyles } from '../../components/container';
import Modal from 'react-modal';

import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import indigo from '@material-ui/core/colors/indigo'
import yellow from '@material-ui/core/colors/yellow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown, faCheck, faEye, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import "./AlertsListSettings.scss";
import { get_simple_far_text } from '../../far';

export class AlertFilterSettings {
    farThreshold: number = FAR_DEFAULT_SIGNIFICANCE_THRESHOLD;
    farThresholdEnabled: boolean = true;
    retracted: boolean = false;
    filters: AlertFilterMap = new AlertFilterMap();
}

interface AlertColourKeyProps {
}

const AlertColourKey: FC<AlertColourKeyProps> = ({}) => {
    const dispatch = useDispatch();

    const showBbh = useSelector((state: RootState) => state.alertListFilter.showBbh);
    const showBns = useSelector((state: RootState) => state.alertListFilter.showBns);
    const showNsbh = useSelector((state: RootState) => state.alertListFilter.showNsbh);
    const showNoise = useSelector((state: RootState) => state.alertListFilter.showNoise);
    const showMassgap = useSelector((state: RootState) => state.alertListFilter.showMassgap);
    const showBurst = useSelector((state: RootState) => state.alertListFilter.showBurst);

    return (
        <ul className="alert-list-settings-toggles">
            <li onClick={() => dispatch(toggleShowBbh())}><span className="colour-box" style={{"background" : showBbh ? blue['100'] : 'none'}}>{showBbh ? <FontAwesomeIcon icon={faEye as IconProp} /> : ""}</span><span className="key-label">Binary Black Hole Merger</span></li>
            <li onClick={() => dispatch(toggleShowBns())}><span className="colour-box" style={{"background" : showBns ? indigo['100'] : 'none'}}>{showBns ? <FontAwesomeIcon icon={faEye as IconProp} /> : ""}</span><span className="key-label">Binary Neutron Star Collision</span></li>
            <li onClick={() => dispatch(toggleShowNsbh())}><span className="colour-box" style={{"background" : showNsbh ? green['100'] : 'none'}}>{showNsbh ? <FontAwesomeIcon icon={faEye as IconProp} /> : ""}</span><span className="key-label">Neutron Star - Black Hole Collision</span></li>
            <li onClick={() => dispatch(toggleShowNoise())}><span className="colour-box" style={{"background" : showNoise ? orange['100'] : 'none'}}>{showNoise ? <FontAwesomeIcon icon={faEye as IconProp} /> : ""}</span><span className="key-label">Terrestrial Noise</span></li>
            <li onClick={() => dispatch(toggleShowMassgap())}><span className="colour-box" style={{"background" : showMassgap ? purple['100'] : 'none'}}>{showMassgap ? <FontAwesomeIcon icon={faEye as IconProp} /> : ""}</span><span className="key-label">Source between 3 &amp; 5 Solar masses</span></li>
            <li onClick={() => dispatch(toggleShowBurst())}><span className="colour-box" style={{"background" : showBurst ? yellow['50'] : 'none'}}>{showBurst ? <FontAwesomeIcon icon={faEye as IconProp} /> : ""}</span><span className="key-label">Burst Candidate</span></li>
        </ul>
    );
}

interface AlertListSettingsProps {
}

const getInfoModal = (modalOpen: boolean, setModalOpen: React.Dispatch<React.SetStateAction<boolean>>)  => {
    return (<span>
        <span className="modal-open-icon" onClick={() => setModalOpen(!modalOpen)}><FontAwesomeIcon icon="info-circle" /></span>
        <Modal
          isOpen={modalOpen}
          onAfterOpen={() => {}}
          onRequestClose={() => setModalOpen(false)}
        style={modalStyles}
        className="Modal"
        overlayClassName="Overlay"
          contentLabel="Example Modal"
       		>
        <div className="close-button" onClick={() => setModalOpen(false)}>x</div>
        <div className="modal-content-container">
        <h2>New - Alert Filtering</h2>
            <p>
                This settings pane allows you to filter alerts to your liking.
                To expand the settings pane, click the 'Filter' heading. 
                You can select which types you wish to see in the alert list by toggling the appropriate item in the alerts key.
                You can also optionally show retracted alerts by ticking the 'Show Retracted Alerts' checkbox. 
            </p>
            <p>
                By default we also now show only significant events (events with a false alarm rate of less than {FAR_DEFAULT_SIGNIFICANCE_THRESHOLD}, corresponding to a false alarm rate of {get_simple_far_text(FAR_DEFAULT_SIGNIFICANCE_THRESHOLD)}).
                This threshold can be removed by unticking 'Show Only Significant Events'. 
            </p>
        </div>
    </Modal>
    </span>
    )
}

const AlertListSettings: FC<AlertListSettingsProps> = ({}) => {
    const farThresholdEnabled = useSelector((state: RootState) => state.alertListFilter.farThresholdEnabled);
    const farThreshold = useSelector((state: RootState) => state.alertListFilter.farThreshold);
    const retracted = useSelector((state: RootState) => state.alertListFilter.retracted);
    const showBbh = useSelector((state: RootState) => state.alertListFilter.showBbh);
    const showBns = useSelector((state: RootState) => state.alertListFilter.showBns);
    const showNsbh = useSelector((state: RootState) => state.alertListFilter.showNsbh);
    const showNoise = useSelector((state: RootState) => state.alertListFilter.showNoise);
    const showMassgap = useSelector((state: RootState) => state.alertListFilter.showMassgap);
    const showBurst = useSelector((state: RootState) => state.alertListFilter.showBurst);
    const dispatch = useDispatch();

    const [filterUiShown, toggleFilterUi] = useState(false);
    const [infoModalShown, setInfoModalShown] = useState(false);

    useEffect(() => {
        Modal.setAppElement("#container");
    });

    return (
        <div className="App-main-content" id="container">
            <div className="alert-settings-container">
                <div className='row'>
                    <button onClick={() => toggleFilterUi(!filterUiShown)} className='alert-settings-toggle-button col-10 col-md-11'>
                        <FontAwesomeIcon icon={ filterUiShown ? faCaretDown as IconProp : faCaretRight as IconProp} className='alert-settings-toggle-arrow'/>
                        &nbsp;
                        <h3 className='alert-list-settings-heading'>Filter</h3>
                        &nbsp;
                    </button>
                    <span className="alert-settings-info-button col-2 col-md-1">
                        {getInfoModal(infoModalShown, setInfoModalShown)}
                    </span>
                </div>
                <div style={{"display" : filterUiShown ? "block" : "none"}}>
                    <AlertColourKey />
                    <div className='alert-settings-form-vspacer'></div>
                    <ul className="alert-list-settings-toggles">
                        <li onClick={() => dispatch(toggleRetracted())}><span className="colour-box" style={{"background" : retracted ? red['100'] : 'none'}}>{retracted ? <FontAwesomeIcon icon={faCheck as IconProp} color={red[800]} /> : ""}</span><span className="key-label">Show Retracted Alerts</span></li>
                        <li onClick={() => dispatch(toggleFarThreshold())}><span className="colour-box far-threshold-box">{farThresholdEnabled ? <FontAwesomeIcon icon={faCheck as IconProp} /> : ""}</span><span className="key-label">Show Only Significant Events</span></li>
                    </ul>
                    
                    <div className='alert-settings-form-vspacer'></div>
                    <Button 
                        variant="contained" 
                        color="primary"  
                        onClick={() => {
                            var filts = new AlertFilterMap();
                            filts.bbh = showBbh;
                            filts.bns = showBns;
                            filts.nsbh = showNsbh;
                            filts.noise = showNoise;
                            filts.massgap = showMassgap;
                            filts.burst = showBurst;
                            dispatch(invalidateAlertsList());
                            dispatch(retrieveAlertsPage(0, farThresholdEnabled ? farThreshold : 1.0, retracted, filts));
                        }}>Update</Button>
                </div>
            </div>
        </div>
    )
}

export default AlertListSettings;