import { FAR_DEFAULT_SIGNIFICANCE_THRESHOLD } from "../../consts";
import { ALERT_LIST_SET_FAR_THRESHOLD, ALERT_LIST_TOGGLE_BBH, ALERT_LIST_TOGGLE_BNS, ALERT_LIST_TOGGLE_BURST, ALERT_LIST_TOGGLE_FAR_THRESHOLD, ALERT_LIST_TOGGLE_MASSGAP, ALERT_LIST_TOGGLE_NOISE, ALERT_LIST_TOGGLE_NSBH, ALERT_LIST_TOGGLE_RETRACTED } from "../actions/actionTypes";

const initialState = {
    farThreshold: FAR_DEFAULT_SIGNIFICANCE_THRESHOLD,
    farThresholdEnabled: true,
    retracted: false,
    showBbh: true,
    showBns: true, 
    showNsbh: true,
    showNoise: false,
    showMassgap: true,
    showBurst: true,
};

export default function alertListshow(state = initialState, action) {
    switch(action.type) {
        case ALERT_LIST_TOGGLE_RETRACTED:
            return {
                ...state,
                retracted: !state.retracted,
            };
        
        case ALERT_LIST_TOGGLE_FAR_THRESHOLD:
            return {
                ...state,
                farThresholdEnabled: !state.farThresholdEnabled,
            };
        
        case ALERT_LIST_SET_FAR_THRESHOLD:
            return {
                ...state,
                farThreshold: action.value,
            };

        case ALERT_LIST_TOGGLE_BBH:
            return {
                ...state,
                showBbh: !state.showBbh,
            }
        
        case ALERT_LIST_TOGGLE_BNS:
            return {
                ...state,
                showBns: !state.showBns,
            }
        
        case ALERT_LIST_TOGGLE_NSBH:
            return {
                ...state,
                showNsbh: !state.showNsbh,
            }
        
        case ALERT_LIST_TOGGLE_NOISE:
            return {
                ...state,
                showNoise: !state.showNoise,
            };
        
        case ALERT_LIST_TOGGLE_MASSGAP:
            return {
                ...state,
                showMassgap: !state.showMassgap,
            };
        
        case ALERT_LIST_TOGGLE_BURST:
            return {
                ...state,
                showBurst: !state.showBurst,
            };

        default:
            return state;
    }
}