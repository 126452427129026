export const FAR_MONTH_CUTOFF = 3600.0 * 24.0 * (365.25 / 12.0);
export const FAR_YEAR_CUTOFF = 3600.0 * 24.0 * 365.25;
export const AGE_OF_EARTH = 4.543e9;
export const AGE_OF_UNIVERSE = 1.3799e10; // From Planck / LambdaCDM model. 

export const output_far_units = (far) => {
    if (far > 1E3) {
        // Let's convert to scientific notation
        return far.toExponential(3);
    } else {
        // Leave as-is, but round to 4DP anyway. 
        return far.toFixed(1);
    }
}

export const get_far_text = (far) => {
    var pre = "1 per";

    if (( 1.0 / far ) / FAR_YEAR_CUTOFF >= AGE_OF_UNIVERSE ) {
        var far_universe_age = ( 1.0 / far ) / (FAR_YEAR_CUTOFF * AGE_OF_UNIVERSE);
        return `${pre} ${simplify_years(far_universe_age)} times the age of the Universe`;
    } 

    if (( 1.0 / far ) / FAR_YEAR_CUTOFF >= 1E9 ) {
        var far_earth_age = ( 1.0 / far ) / (FAR_YEAR_CUTOFF * AGE_OF_EARTH);
        return `${pre} ${simplify_years(far_earth_age)} times the age of Earth`;
    }

    if ( 1.0 / far > FAR_YEAR_CUTOFF ) {
        var far_yr = ( 1.0 / far ) / FAR_YEAR_CUTOFF;
        return `${pre} ${output_far_units(far_yr)} years`;
    }

    if ( 1.0 / far > FAR_MONTH_CUTOFF ) {
        var far_mnth = ( 1.0 / far ) / FAR_MONTH_CUTOFF;
        return `${pre} ${output_far_units(far_mnth)} months`;
    }

    var far_per_year = far * 3600.0 * 24 * 365.25;
    return `${far_per_year.toFixed(2)} per year`;
}

export const get_simple_far_text = (far) => {
    var pre = "1 per";

    if (( 1.0 / far ) / FAR_YEAR_CUTOFF >= AGE_OF_UNIVERSE ) {
        var far_universe_age = ( 1.0 / far ) / (FAR_YEAR_CUTOFF * AGE_OF_UNIVERSE);
        return `${pre} ${simplify_years(far_universe_age)} times the age of the Universe`;
    } 

    if (( 1.0 / far ) / FAR_YEAR_CUTOFF >= 1E9 ) {
        var far_earth_age = ( 1.0 / far ) / (FAR_YEAR_CUTOFF * AGE_OF_EARTH);
        return `${pre} ${simplify_years(far_earth_age)} times the age of Earth`;
    }

    if ( 1.0 / far > FAR_YEAR_CUTOFF ) {
        var far_yr = ( 1.0 / far ) / FAR_YEAR_CUTOFF;
        return `${pre} ${simplify_years(far_yr)} years`;
    }

    if ( 1.0 / far > FAR_MONTH_CUTOFF ) {
        var far_mnth = ( 1.0 / far ) / FAR_MONTH_CUTOFF;
        return `${pre} ${output_far_units(far_mnth)} months`;
    }

    var far_per_year = far * 3600.0 * 24 * 365.25;
    return `${far_per_year.toFixed(2)} per year`;
}

export const simplify_years = (far) => {
    var logfar = Math.log10(far);

    if (logfar < 3.0)
        return `${far.toFixed(2)}`;
    if (logfar < 6.0)
        return `${(far / 1E3).toFixed(2)} thousand`;
    if (logfar < 9.0)
        return `${(far / 1E6).toFixed(2)} million`;
    if (logfar < 12.0)
        return `${(far / 1E9).toFixed(2)} billion`;
    if (logfar < 15.0)
        return `${(far / 1E12).toFixed(2)} trillion`;
    if (logfar < 18.0)
        return `${(far / 1E15).toFixed(2)} quadrillion`;
    else
        return `${(far / 1E18).toFixed(2)} quintillion`;
}