/**
 * Represents the filtering of alerts for 
 */
export class AlertFilterMap {
    bbh: boolean = true;
    bns: boolean = true;
    nsbh: boolean = true;
    noise: boolean = false;
    massgap: boolean = true;
    burst: boolean = true;

    isDefault(): boolean {
        return this.bbh && this.bns && this.nsbh && !this.noise && this.massgap && this.burst;
    }
}

export const stringArrsFromFilterMap = (map: AlertFilterMap): string[] => {
    var ret: string[] = [];
    if (!map.bbh) ret.push('bbh');
    if (!map.bns) ret.push('bns');
    if (!map.nsbh) ret.push('nsbh');
    if (!map.noise) ret.push('noise');
    if (!map.massgap) ret.push('massgap');
    if (!map.burst) ret.push('burst');
    return ret;
}

export type AlertFilterMapPropKeys = keyof AlertFilterMap;