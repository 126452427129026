import React, { Component } from 'react';
import Modal from 'react-modal';
import './container.scss'; // Import css modules stylesheet as styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tooltips from '../alert-field-tooltips.json';
import ReactTooltip from 'react-tooltip'

export const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : '25%',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class ContainerRow extends Component {

	constructor() {
		super();
		
		this.state = {
			modalIsOpen: false,
		}

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
	}
	
	get_modal(field) {
		if ( Object.keys(tooltips).includes(field) ) {
			return (
				<span>
	      	<span className="modal-open-icon" data-tip={tooltips[field]} onClick={this.openModal}><FontAwesomeIcon icon="info-circle" /></span>
	      	<Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
					style={modalStyles}
					className="Modal"
					overlayClassName="Overlay"
          contentLabel="Example Modal"
       		>
					 <div className="close-button" onClick={this.closeModal}>x</div>
					 <div className="modal-content-container">
						<h2>{this.props.label}</h2>
						{tooltips[field]}
					 </div>
					</Modal>
      	</span>
			)
		}
	}

	get_tooltip(field) {
    if ( Object.keys(tooltips).includes(field) ) {
      return ( 
      	<span>
	      	<span data-tip={tooltips[field]}><FontAwesomeIcon icon="info-circle" /></span>
	      	<ReactTooltip className="tooltip-container" multiline={true} effect='solid' />
      	</span>
      	);
    }
    return (
    	<span></span>
    );
  }

	render() {
		return (
			<li className='list-item'>
				<span className="list-item-label">
					<span className='list-item-property'>{this.props.label}</span> &nbsp; 
					{this.get_modal(this.props.field)} &nbsp; : &nbsp;
				</span>
				<span className='list-item-value'>{ (this.props.value!==null) ? this.props.value:'N/A'}</span>
			</li>
		)
	}
}

class Container extends Component {

	ContainerTitle() {
		if (this.props.title) 
			return ( <h1 className='Container-title'>{this.props.title}</h1> )
		return <div></div>
	}

	get_field(i) {
		if (this.props.fields) {
			if (this.props.fields.length > i) {
				return this.props.fields[i];
			}
		}
		return "";
	}

	render() {

		var title = "";
		if (this.props.title) {
			title = <h1 className='Container-title'>{this.props.title}</h1>;
		}

		var labels = []
		if (this.props.labels) {
			labels = this.props.labels;
		} else {
			labels = Object.keys(this.props.items)
		}

		return(
			<div className="content-Container">
				{title}
				<ul className="propContainer">
					{Object.keys(this.props.items).map((key, i) => {
				        return <ContainerRow value={this.props.items[key]} label={labels[i]} field={this.get_field(i)} key={key}/>;
				    })}
				</ul>
			</div>		
		)
	}
} 

export default Container;