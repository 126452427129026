import React, {Component} from 'react';
import './Home.scss';
import {Link} from 'react-router-dom';

class Home extends Component {

    componentDidMount() {
        this.content_class = document.getElementById('content').className;
        document.getElementById('content').className = this.content_class + (this.content_class ? ' ' : '') + 'home-content'; //Here gray-by is the bg color which I have set
    }
    componentWillUnmount() {
        document.getElementById('content').className = this.content_class ;
    }

	masthead_content() {
		return (
			<div>
				<p>
					<a href="https://www.ligo.caltech.edu/page/gravitational-waves">
					Gravitational waves
					</a> are ripples in space-time. The two LIGO detectors, 
					<a href="https://www.ligo.caltech.edu/WA"> Hanford </a>
					and <a href="https://www.ligo.caltech.edu/LA/"> Livingston </a>
					along with <a href="http://www.virgo-gw.eu/"> Virgo </a>
					have a proven track record of successful observations and are
					now issuing public alerts of possible gravitational waves
					events via the 
					<a href="https://gcn.gsfc.nasa.gov/"> Gamma Ray
					Coordinates (GCN)</a> system. 
				<br /><br />
					<i>Chirp</i> is a web-app that listens to the GCN system and displays
					the information in a friendly format, with links to <a href="https://gracedb.ligo.org/">
					LIGO-Virgo Gravitational Wave Candidate Event Database
					(GraceDB)</a> pages for more detailed information.
					For more information about this app check out 
					our <Link to='/about'>about page</Link>.
				</p>
			</div>
		);
	}

	render() {

		return (
			<div className="home-masthead-container">
				<div className="container">
					<h1 className="home-title"><i>Chirp</i> - Keep track of the latest gravitational wave alerts</h1>


                    <div className="home-masthead-content">{this.masthead_content()}</div>
					
					<Link to="/latest">
						<button type="button" className="btn btn-outline-light home-masthead-button">Latest Alert</button>
					</Link>
					<Link to="/alerts">
						<button type="button" className="btn btn-outline-light home-masthead-button">All Alerts</button>
					</Link>
					{/*
					<br />
					<h3> Also available on mobile </h3>
					<br />
					<a href='https://play.google.com/store/apps/details?id=org.laserlabs.chirp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' className=""><img className="storeButton" alt='Get it on Google Play' src='https://play.google.com/intl/en_gb/badges/static/images/badges/en_badge_web_generic.png'/></a>
					<a href="https://apps.apple.com/us/app/chirp-gravitational-wave-app/id1484328193?mt=8" className=""><img src='https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-10-28&kind=iossoftware&bubble=ios_apps' className="appleLogo" alt="Download on the App Store"></img></a>
					<br />
					*/}
					<br /><br />
					<div className="alert alert-secondary">
						We are currently updating our iOS and Android apps for O4. Until Chirp is back online, if you are an iOS user you can use the <a href="https://apps.apple.com/app/id1441897107" target="_blank" rel="noopener noreferrer" class="alert-link">Gravitational Wave Alerts app</a> to receive alerts on your phone.
					</div>
					<p className="right"> Image credit:  Aurore Simonnet, EPO Sonoma State University</p>			
				</div>
			</div>
			)

	}

}

export default Home;