// Here we'll store all the strings for each action 

// GET ALERT LISTS

export const FETCH_ALERT_LIST_BEGIN = 'FETCH_ALERT_LIST_BEGIN';
export const FETCH_ALERT_LIST_SUCCESS = 'FETCH_ALERT_LIST_SUCCESS';
export const FETCH_ALERT_LIST_FAILURE = 'FETCH_ALERT_LIST_FAILURE';
export const INVALIDATE_ALERT_LIST = 'INVALIDATE_ALERT_LIST';

// GET LATEST ALERT 

export const FETCH_LATEST_ALERT_BEGIN = 'FETCH_LATEST_ALERT_BEGIN';
export const FETCH_LATEST_ALERT_SUCCESS = 'FETCH_LATEST_ALERT_SUCCESS';
export const FETCH_LATEST_ALERT_FAILURE = 'FETCH_LATEST_ALERT_FAILURE';

// FETCH SINGLE ALERT

export const REQUEST_SINGLE_ALERT = 'REQUEST_SINGLE_ALERT';
export const FETCH_SINGLE_ALERT_BEGIN = 'FETCH_SINGLE_ALERT_BEGIN';
export const FETCH_SINGLE_ALERT_SUCCESS = 'FETCH_SINGLE_ALERT_SUCCESS';
export const FETCH_SINGLE_ALERT_FAILURE = 'FETCH_SINGLE_ALERT_FAILURE';

// FETCH OBSERVATORY STATUS

export const REQUEST_OBSERVATORY_STATUS = 'REQUEST_OBSERVATORY_STATUS';
export const FETCH_OBSERVATORY_STATUS_BEGIN = 'FETCH_OBSERVATORY_STATUS_BEGIN';
export const FETCH_OBSERVATORY_STATUS_SUCCESS = 'FETCH_OBSERVATORY_STATUS_SUCCESS';
export const FETCH_OBSERVATORY_STATUS_FAILURE = 'FETCH_OBSERVATORY_STATUS_FAILURE';

// ALERT LIST FILTERING
export const ALERT_LIST_TOGGLE_RETRACTED = 'ALERT_LIST_TOGGLE_RETRACTED';
export const ALERT_LIST_TOGGLE_FAR_THRESHOLD = 'ALERT_LIST_TOGGLE_FAR_THRESHOLD';
export const ALERT_LIST_SET_FAR_THRESHOLD = 'ALERT_LIST_SET_FAR_THRESHOLD';
export const ALERT_LIST_TOGGLE_BBH = 'ALERT_LIST_TOGGLE_BBH'; 
export const ALERT_LIST_TOGGLE_BNS = 'ALERT_LIST_TOGGLE_BNS';
export const ALERT_LIST_TOGGLE_NSBH = 'ALERT_LIST_TOGGLE_NSBH';
export const ALERT_LIST_TOGGLE_NOISE = 'ALERT_LIST_TOGGLE_NOISE';
export const ALERT_LIST_TOGGLE_MASSGAP = 'ALERT_LIST_TOGGLE_MASSGAP';
export const ALERT_LIST_TOGGLE_BURST = 'ALERT_LIST_TOGGLE_BURST';