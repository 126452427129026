import {
    ALERT_LIST_TOGGLE_FAR_THRESHOLD,
    ALERT_LIST_SET_FAR_THRESHOLD,
    ALERT_LIST_TOGGLE_RETRACTED,
    ALERT_LIST_TOGGLE_BBH,
    ALERT_LIST_TOGGLE_BNS,
    ALERT_LIST_TOGGLE_NSBH,
    ALERT_LIST_TOGGLE_NOISE,
    ALERT_LIST_TOGGLE_MASSGAP,
    ALERT_LIST_TOGGLE_BURST
} from "./actionTypes";

export function toggleFarThreshold(): Object {
    return {
        type: ALERT_LIST_TOGGLE_FAR_THRESHOLD,
    };
}

export function setFarThreshold(farThreshold: number): Object {
    return {
        type: ALERT_LIST_SET_FAR_THRESHOLD,
        value: farThreshold,
    };
}

export function toggleRetracted(): Object {
    return {
        type: ALERT_LIST_TOGGLE_RETRACTED,
    };
}

export function toggleShowBbh(): Object {
    return {
        type: ALERT_LIST_TOGGLE_BBH,
    };
}

export function toggleShowBns(): Object {
    return {
        type: ALERT_LIST_TOGGLE_BNS,
    };
}

export function toggleShowNsbh(): Object {
    return {
        type: ALERT_LIST_TOGGLE_NSBH,
    };
}

export function toggleShowNoise(): Object {
    return {
        type: ALERT_LIST_TOGGLE_NOISE,
    };
}

export function toggleShowMassgap(): Object {
    return {
        type: ALERT_LIST_TOGGLE_MASSGAP,
    };
}

export function toggleShowBurst(): Object {
    return {
        type: ALERT_LIST_TOGGLE_BURST,
    };
}
