import React, { Component } from 'react';
import './AlertsList.scss'; // Import css modules stylesheet as styles

import AlertsTable from './AlertsTable.js';
import AlertListSettings from './AlertsListSettings';
import Loading from '../Loading';

import {retrieveAlertsPage, invalidateAlertsList} from '../../redux/actions/fetchAlertList';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { AlertFilterMap } from '../../Alerts/alertFilterMap';

const ALERTS_PER_PAGE = 10;
const CACHE_TIME = 60 * 1000; // 1 minute

// import items from '../eventsList.json'
const table_labels = ['GraceDB ID','Time Observed','False Alarm Rate','Most Likely Origin','Distance Range (Mpc)', 'Detected By']

class AlertsList extends Component {
  
  constructor(props) {
    super(props);
		this.state = {
			loading: true,
      failed: false,
			items: [],
		};
  }

  componentWillMount() {
    const {
      alerts,
      farThresholdEnabled,
      farThreshold,
      retracted,
      showBbh,
      showBns,
      showNsbh,
      showMassgap,
      showNoise,
      showBurst
    } = this.props;

    var filts = new AlertFilterMap();
    filts.bbh = showBbh;
    filts.bns = showBns;
    filts.nsbh = showNsbh;
    filts.noise = showNoise;
    filts.massgap = showMassgap;
    filts.burst = showBurst;

    if (alerts.length === 0 || this.is_expired()) {
      this.props.invalidate_alerts_list();
      this.props.get_alerts_page(0, farThresholdEnabled ? farThreshold : 1.0, retracted, filts);
    }
  }

  getPage() {
    const n_fetched = this.props.alerts.length;
    const {
      farThresholdEnabled,
      farThreshold,
      retracted,
      showBbh,
      showBns,
      showNsbh,
      showMassgap,
      showNoise,
      showBurst
    } = this.props;

    var filts = new AlertFilterMap();
    filts.bbh = showBbh;
    filts.bns = showBns;
    filts.nsbh = showNsbh;
    filts.noise = showNoise;
    filts.massgap = showMassgap;
    filts.burst = showBurst;

    this.props.get_alerts_page(n_fetched, farThresholdEnabled ? farThreshold : 1.0, retracted, filts);
  }

  formatItems(items) {
    for (var i=0; i<items.length;i++) {
      if (items[i].distmean==null) {
          items[i].distRange = <span> "N/A" </span>
      }
      else {
        items[i].distRange = <span>{(items[i].distmean - items[i].diststd).toFixed(0)} - {(items[i].distmean + items[i].diststd).toFixed(0)}</span>
      }
    }
    return (items)
  }

  is_expired() {
    const {t_fetched} = this.props;
    const test_date = t_fetched.getTime() + CACHE_TIME;
    if (test_date >= Date.now()) {
        return false;
    } else {
        return true;
    }
  }

	render() {

    const {alerts, loading, failed, prev_n_alerts} = this.props;

   return (
      <div>
        <AlertListSettings />
        <AlertsTable values={this.formatItems(alerts)} labels={table_labels}></AlertsTable>
        {loading ? <Loading color='black' type='spin' /> : ""}
        {failed ? <div>Unable to load alerts. </div> : ""}
        <div className="load-more-container">
          { (prev_n_alerts + ALERTS_PER_PAGE <= alerts.length) && (!loading) ? <input type="button" value={"Load More"} className="load-more-button" onClick={() => this.getPage()}></input> : ""}
        </div>
      </div>
    )
	}
}

AlertsList.propTypes = {
	alerts: PropTypes.array,
  get_alerts_page: PropTypes.func,
  invalidate_alerts_list: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        alerts: state.alertsList.alerts_list,
        loading: state.alertsList.loading,
        failed: state.alertsList.failed,
        prev_n_alerts: state.alertsList.prev_n_alerts,
        t_fetched: state.alertsList.t_fetched,
        farThresholdEnabled: state.alertListFilter.farThresholdEnabled,
        farThreshold: state.alertListFilter.farThreshold,
        retracted: state.alertListFilter.retracted,
        showBbh: state.alertListFilter.showBbh,
        showBns: state.alertListFilter.showBns,
        showNsbh: state.alertListFilter.showNsbh,
        showNoise: state.alertListFilter.showNoise,
        showMassgap: state.alertListFilter.showMassgap,
        showBurst: state.alertListFilter.showBurst,
    }
}

const mapDispatchToProps = dispatch => ({
    get_alerts_page: (offset = 0, far_significance_threshold, retracted, filtered_types) => dispatch(retrieveAlertsPage(offset, far_significance_threshold, retracted, filtered_types)),
    invalidate_alerts_list: () => dispatch(invalidateAlertsList()),
})

const AlertsListContainer = connect(mapStateToProps, mapDispatchToProps)(AlertsList);
export default AlertsListContainer;