import React, { Component } from 'react';
import {Table, TableBody, TableCell, TableHead,TableRow, Hidden} from '@material-ui/core'
import {Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { segment_alert_list } from '../../alerts';

import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import indigo from '@material-ui/core/colors/indigo'
import yellow from '@material-ui/core/colors/yellow';
import './AlertsTable.scss'; // Import css modules stylesheet as styles

class AlertTable extends Component {

	get_type_cell(alert) {
		var type = "";
		if (alert.retracted) {
			type = withStyles(theme => ({
				body: {
					backgroundColor: red['100'],
					color: red['800'],
				},
			}))(TableCell);
		} else if (alert.type === 'Burst') {
			type = withStyles(theme => ({
				body: {
					backgroundColor: yellow['50'],
				},
				}))(TableCell);
		} else {
			switch (alert.most_likely_type()) {
				case 'bbh' : 
					type = withStyles(theme => ({
						body: {
							backgroundColor: blue['100'],
						},
					}))(TableCell);
					break;
				case 'bns' :
					type = withStyles(theme => ({
					body: {
						backgroundColor: indigo['100'],
					},
					}))(TableCell);
					break;
				case 'nsbh':
					type = withStyles(theme => ({
						body: {
							backgroundColor: green['100'],
						},
					}))(TableCell);
					break;
		
				case 'noise':
					type = withStyles(theme => ({
						body: {
							backgroundColor: orange['100'],
						},
						}))(TableCell);
					break;
		
				case 'massgap':
					type = withStyles(theme => ({
						body: {
							backgroundColor: purple['100'],
						},
						}))(TableCell);
					break;
		
				default:
					type = TableCell;
					break;
				}	
		}
	
		return type;
	}

	get_instruments_item(alert) {
		var insts = [];
		if (alert.instruments.includes("H1"))
		  insts.push(<li key="h1"><a href="https://www.ligo.caltech.edu/WA" target="_blank" rel="noopener noreferrer">H1</a></li>);
		if (alert.instruments.includes("L1"))
		  insts.push(<li key="l1"><a href="https://www.ligo.caltech.edu/LA" target="_blank" rel="noopener noreferrer">L1</a></li>);
		if (alert.instruments.includes("V1"))
		  insts.push(<li key="v1"><a href="http://www.virgo-gw.eu/" target="_blank" rel="noopener noreferrer">V1</a></li>);
		if (alert.instruments.includes("K1"))
		  insts.push(<li key="k1"><a href="https://gwcenter.icrr.u-tokyo.ac.jp/en/" target="_blank" rel="noopener noreferrer">K1</a></li>);
		return (
		  <ul id="instrument-list">{insts}</ul>
		)
	  }

	alert_colour_key() {
		return (
			<div className="alertlist-colour-key-container">
				<ul className="key-list">
					<li><span className="key-colour-box" style={{"background" : red['100']}}></span><span className="key-label" style={{"color" : red['800']}}>Retracted Alert</span></li>
					<li><span className="key-colour-box" style={{"background" : blue['100']}}></span><span className="key-label">Binary Black Hole Merger</span></li>
					<li><span className="key-colour-box" style={{"background" : indigo['100']}}></span><span className="key-label">Binary Neutron Star Collision</span></li>
					<li><span className="key-colour-box" style={{"background" : green['100']}}></span><span className="key-label">Neutron Star - Black Hole Collision</span></li>
					<li><span className="key-colour-box" style={{"background" : orange['100']}}></span><span className="key-label">Terrestrial Noise</span></li>
					<li><span className="key-colour-box" style={{"background" : purple['100']}}></span><span className="key-label">Source between 3 &amp; 5 Solar masses</span></li>
					<li><span className="key-colour-box" style={{"background" : yellow['50']}}></span><span className="key-label">Burst Candidate</span></li>
				</ul>
			</div>
		)
	}

	render() {
		// Get the segmented alerts list. 
		var segments = segment_alert_list(this.props.values);

		// Keep a tally of the index for each row. 
		var index = 0;
		
		return (
			<div className="App-main-content">
				<Table>
				<TableHead>
					<TableRow> 
						<TableCell>{this.props.labels[0]}</TableCell>
						<TableCell>{this.props.labels[1]}</TableCell>
						<Hidden smDown>
							<TableCell>{this.props.labels[2]}</TableCell>
						</Hidden>
						<Hidden xsDown>
							<TableCell>{this.props.labels[3]}</TableCell>
						</Hidden>
						<Hidden smDown>
							<TableCell className="hide-cell-on-mofile">{this.props.labels[4]}</TableCell>
						</Hidden>
						<Hidden smDown>
							<TableCell className="hide-cell-on-mofile">{this.props.labels[5]}</TableCell>
						</Hidden>
					</TableRow>
				</TableHead>
				<TableBody>
					{segments.map((segment, _) => {
						var alert_rows = segment['alerts'].map((row) => {
							var CellTagName = this.get_type_cell(row);
							index += 1;
							return <TableRow component={Link} to={'/alert/'+row.graceid} className='table-row-link' key={index - 1}>
									<CellTagName>
											{row.graceid}
									</CellTagName>
									<CellTagName>{row.t_observed ? row.t_observed.toLocaleString('en-GB', { timeZone: 'UTC', timeZoneName: 'short' }) :'N/A'}</CellTagName>				
									<Hidden smDown>
										<CellTagName>{row.far ? row.far_simple_text:'N/A'}</CellTagName>
									</Hidden>
									<Hidden xsDown>
										<CellTagName>{row.get_type_text() ? row.get_type_text() :'N/A'}</CellTagName>
									</Hidden>
									<Hidden smDown>
										<CellTagName>{row.distRange ? row.distRange : 'N/A'}</CellTagName>
									</Hidden>
									<Hidden smDown>
										<CellTagName>{row.instruments ? this.get_instruments_item(row) : 'N/A'}</CellTagName>
									</Hidden>
								</TableRow>
						});

						return [
							<TableRow className='table-segment-head-row'>
								<TableCell colSpan={6}>{segment.label}</TableCell>
							</TableRow>,
							alert_rows
						]
					})}
				</TableBody>
			</Table>

			</div>
		)
	}
}

export default AlertTable;

